import { Link } from 'react-router-dom';
import logo from '../assets/bg-astonish-logo.png';
import { BiPhoneCall } from 'react-icons/bi';
import { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header className="max-w-[1440px] mx-auto w-full fixed top-0 left-0 right-0 h-20 bg-white shadow-md flex items-center justify-between px-4 z-50">
      <div className="ml-2 h-20">
        <Link to="/">
          <img src={logo} alt="logo" className="max-h-[90%] hover:scale-110" />
        </Link>
      </div>
      {/* Menu icon for smaller screens */}
      <div className="md:hidden flex items-center">
        <div className="text-primary-orange text-2xl cursor-pointer mr-4" onClick={toggleMenu}>
          {isMenuOpen ? <span>&#4030;</span> : <span>&#9776;</span>}
        </div>
        {/* Navigation links for mobile */}
        {isMenuOpen && (
          <nav className="absolute w-full top-20 left-0 right-0 left-0 bg-white opacity-95 shadow-md py-4 md:hidden">
            <ul className="flex flex-col items-center space-y-4">
              <li>
                <Link to="/about-us" className="hover:text-primary-orange" onClick={toggleMenu}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/our-products" className="hover:text-primary-orange" onClick={toggleMenu}>
                  Our Products
                </Link>
              </li>
              <li>
                <Link to="/dealership-form" className="hover:text-primary-orange" onClick={toggleMenu}>
                  Dealership Form
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="hover:text-primary-orange" onClick={toggleMenu}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
      {/* Navigation links for desktop */}
      <nav className="hidden md:flex-1 md:flex justify-center space-x-6 ml-12">
        <Link to="/about-us" className="hover:text-primary-orange">
          About Us
        </Link>
        <span className="mx-4 text-primary-orange">|</span>
        <Link to="/our-products" className="hover:text-primary-orange">
          Our Products
        </Link>
        <span className="mx-4 text-primary-orange">|</span>
        <Link to="/dealership-form" className="hover:text-primary-orange">
          Dealership Form
        </Link>
      </nav>
      <nav className="hidden md:block mr-4 space-x-4 hover:scale-110">
        <Link to="/contact-us" className="flex items-center">
          <BiPhoneCall className="text-primary-orange mr-2 text-lg 2xl:text-2xl" />
          <div className="">Contact Us</div>
        </Link>
      </nav>
    </header>
  );
};

export default Header;