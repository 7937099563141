import image from "../assets/white-granite-bg.jpg";

const clientData = [
    {
        location: "Rajnagar",
        clients: [
            "Bapu Marbles Pvt Ltd",
            "Jangid Marmo and Granites Pvt Ltd",
            "Keshari Nandan Granites Pvt Ltd",
            "Navalkha Marble Pvt Ltd",
            "Prime Marble Pvt Ltd",
            "Sarvottam Marbles Pvt Ltd",
            "Shiv Ganga Buildcon Pvt Ltd",
            "Shubham Marble Pvt Ltd",
            "Vikas Mines (P) Ltd",
        ],
    },
    {
        location: "Amet",
        clients: [
            "Poonam Marbles Pvt Ltd",
            "Suncity Marble and Granite",
            "Yogmaya Marbles Pvt Ltd",
            "Maa Bhawani Marble and Granite",
        ],
    },
    {
        location: "Kishangarh",
        clients: ["G V Marbles(India) Pvt Ltd"],
    },
];


const Clients = () => {
    const divStyle = {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: "fixed",
    };

    return (
        <div className="center-align relative py-15">
          <div className="absolute top-0 left-0 w-full h-full" style={divStyle}></div>
          <div className="absolute top-0 left-0 w-full h-full bg-primary-orange opacity-10"></div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
          <div className="relative z-10 py-12 px-4">
            <div className="flex flex-col items-center justify-center text-white">
              <h1 className="text-2xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-6 text-center">
                Our Clients
              </h1>
              <div className="flex flex-col justify-center space-y-6">
                {clientData.map((location, index) => (
                  <div key={index} className="w-full max-w-3xl">
                    <div className="p-4 text-center border-dotted border-primary-orange border-2 shadow-lg shadow-primary-grey bg-opacity-10 rounded-lg">
                      <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-5">
                        {location.location}
                      </h2>
                      <div className="flex flex-wrap justify-center">
                        {location.clients.map((client, clientIndex) => (
                          <div key={clientIndex} className="m-2">
                            <div className="font-semibold md:text-sm xl:text-lg lg:text-base p-2 text-center">
                              {client}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-8">
                <a href="/contact-us">
                  <button className="text-center hover:bg-primary-orange hover:border-primary-orange bg-transparent text-white hover:text-white font-bold py-2 px-4 rounded-md border-white border-2 lg:text-lg">
                    Book a Consultation
                  </button>
                </a>
                <a href="/dealership-form">
                  <button className="text-center hover:bg-primary-orange hover:border-primary-orange bg-transparent text-white hover:text-white font-bold py-2 px-4 rounded-md border-white border-2 lg:text-lg">
                    Apply for Dealership
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    };
    

export default Clients;
