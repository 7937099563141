import image from "../assets/2p1.jpg";
import image1 from "../assets/banner-56.jpg";
import About from "../components/About";
import Work from "../components/Work";
import Clients from "../components/Clients";
import "../App.css";

const Home = () => {
  
  const divStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: "fixed",
  };

  return (
    <div>
      <div className="h-screen center-align">
        <div className="relative h-full" style={divStyle}></div>
        <div className="center-align absolute top-0 w-full h-screen bg-black opacity-40"></div>
        <div className="absolute mt-10 inset-0 flex flex-col items-center justify-center z-10">
          <h1 className="text-white text-bold text-lg md:text-2xl lg:text-4xl text-center mb-10">
            <span className="block quote-fade-in">"Beauty lies in the eyes of the beholder and</span>
            <span className="block quote-fade-in">quality lies in the veins of the manufacturer"</span>
          </h1>
          <a href="/our-products">
          <button className="mt-20 md:mt-10 lg:mt-24 hover:bg-primary-orange hover:border-primary-orange bg-transparent text-white hover:text-white font-bold py-2 px-4 rounded-md border-white border-2 md:text-sm lg:text-lg">
            Shop now
          </button>
          </a>
        </div>
      </div>
      <About />
      <Work />
      <Clients />
    </div>
  );
};

export default Home;
