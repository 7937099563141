import image from '../assets/Mix.png';

const Work = () => {
  return (
    <div className="center-align lg:py-10 bg-gray-100 flex flex-col lg:flex-row justify-center items-center px-10">
      <div className="lg:w-2/5 md:w-3/4 h-full flex justify-center items-center">
        <img src={image} alt="Product Assortment" />
      </div>
      <div className="lg:w-3/5 lg:pl-10 flex flex-col justify-center items-center lg:items-start">
        <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-6 text-center lg:text-left">Elevating Stone Fabrication</h1>
        <p className=" md:text-sm xl:text-lg lg:text-base  leading-relaxed text-center lg:text-left">
          Astonish is your partner in perfecting the art of stone fabrication. Our meticulously curated product range caters to the unique needs of Stone Fabricators, delivering exceptional quality, sheen, and results.
          <br />
          <br />
          Immerse yourself in the world of Astonish and revolutionize your stone fabrication experience.        </p>
        <a href="/our-products">
          <button className="my-8 hover:bg-primary-orange hover:border-primary-orange bg-transparent text-black hover:text-white font-bold py-2 px-4 rounded-md border-black border-2 lg:text-lg">
            Explore Our Products
          </button>
        </a>
      </div>
    </div>
  );
};

export default Work;
