import React, { useState } from 'react';
import astopoxyImage1 from '../assets/Astopoxy.jpeg';
import astopoxyImage2 from '../assets/Astopoxy desc.jpeg';
import colorConvertersImage1 from '../assets/color-converter-black.jpeg';
import colorConvertersImage2 from '../assets/color-converter-black-description.jpeg';
import glowPlusImage1 from '../assets/Glow-plus.jpeg';
import glowPlusImage2 from '../assets/glow-plus-text.jpeg';
import miscChemicalsImage1 from '../assets/chemicals.jpeg';
import miscChemicalsImage2 from '../assets/chemicals2.jpeg';
import hardwareImage1 from '../assets/close-up-view-paint-rollers-gray-background.jpg';
import hardwareImage2 from '../assets/abrasive.jpeg';
import Mix from '../assets/Mix.png';

const productData = [
  {
    name: 'Astopoxy',
    images: [astopoxyImage1, astopoxyImage2],
    description: `Discover Astonish’s Epoxy products, known by its brand name 'Astopoxy.' Our Astopoxies are available in various variants - 10%, 25%, 33%, and 50%. These high-viscosity chemicals are meticulously formulated to remedy surface deformities in natural stones, including hairline and major cracks, pinholes, and fissures. They also provide long-lasting gloss and shine to the stone. Customize the color of your Astopoxy to suit your specific needs.`,
  },
  {
    name: 'Color Converters',
    images: [colorConvertersImage1, colorConvertersImage2],
    description: `Introducing Astonish's Color Converters, the solution for all your stone color-related challenges. Our Color Converters deeply penetrate the stone surface, enhancing its color while preserving its natural patterns and textures. Available in a wide range of shades, you can trust us to transform your stone with precision and care.`,
  },
  {
    name: 'Glow Plus',
    images: [glowPlusImage1, glowPlusImage2],
    description: `Experience Astonish’s Glow Plus, a low-viscosity fluid designed to give your stone surface a wet-look and mirror-like shine. Glow Plus deeply penetrates the stone, providing an everlasting shine while preserving the stone's natural texture and pattern. Choose from a range of colors to further enhance the stone's natural shade.`,
  },
  {
    name: 'Miscellaneous Chemicals',
    images: [miscChemicalsImage1, miscChemicalsImage2],
    description: `Our Research and Development wing has crafted unique formulations for various products, including Flocculants, Stone Rust Removers, Cutting Oils, and Stone Shiners. With our specialty chemicals, we are committed to helping you achieve perfection in stone fabrication.`,
  },
  {
    name: 'Hardware',
    images: [hardwareImage1, hardwareImage2],
    description: `Explore our product portfolio, featuring items sourced from industry leaders. We offer a wide range of products, including Rollers, Industrial PPE Kits, and Stone Polishing Abrasives (Resin Bond, Diamond Fickert, Lux). Trust Astonish for quality and excellence in stone fabrication.`,
  },
];

const OurProducts = () => {
  const [expandedProductIndex, setExpandedProductIndex] = useState(-1);

  const handleLearnMoreClick = (productIndex: number) => {
    if (expandedProductIndex === productIndex) {
      setExpandedProductIndex(-1); // Collapse if already expanded
    } else {
      setExpandedProductIndex(productIndex); // Expand the clicked product
    }
  };

  return (
    <div className="center-align bg-gray-100">
      <div className='flex items-center justify-center pt-24 mix-fade-in'>
        <img src={Mix} alt="Mix" className="md:w-1/2 px-2 md:px-0" />
      </div>
      <div className="container mx-auto pb-5">
        {productData.map((product, productIndex) => (
          <div className="flex flex-col md:flex-row md:items-center my-20" key={productIndex}>
            <div className="md:w-1/2 flex justify-center img-fade-in">
              <img
                src={product.images[expandedProductIndex === productIndex ? 1 : 0]}
                alt={product.name}
                className="md:w-3/4 w-[90%]"
              />
            </div>
            <div className="md:w-1/2 md:px-6 text-center md:text-left desc-fade-in">
              <h2 className="lg:text-3xl text-xl font-bold mb-2 mt-10 md:mt-0">{product.name}</h2>
              <p className="lg:text-lg md:text-sm px-5 md:px-0 text-justify md:text-left">{product.description}</p>
              <button onClick={() => handleLearnMoreClick(productIndex)} className="mt-6 bg-primary-orange hover:bg-primary-orange-dark text-white font-bold py-2 px-4 rounded-md">Product Details</button>
              {expandedProductIndex === productIndex && (
                <p className="mt-6 lg:text-lg md:text-sm px-5 md:px-0">
                  For enquiries and quotations, please contact us on astonish.hr@gmail.com
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurProducts;
