import React from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';

const WhyUs = () => {
  const reasons = [
    {
      title: 'High-Quality Products',
      description: 'We provide a comprehensive range of high-quality stone processing products.',
    },
    {
      title: 'Experienced Team',
      description: 'Our team comprises industry experts with years of experience in stone manufacturing and distribution.',
    },
    {
      title: 'Quality Assurance',
      description: 'We are dedicated to providing you with the best quality, shine, and precision in granite and marble processing.',
    },
    {
      title: 'Continuous Innovation',
      description: 'We continually innovate and develop advanced stone processing products to meet your evolving needs.',
    },
  ];

  return ( 
    <div className="bg-white bg-opacity-90 w-full p-6 rounded-md my-10 pt-10">
      <h1 className="text-black text-bold text-4xl text-center mb-10">Why Choose Us?</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5 p-5">
        {reasons.map((reason, index) => (
          <div className="flex items-center mt-10" key={index}>
            <div className="rounded-full bg-primary-orange w-16 h-16 flex items-center justify-center" style={{ minWidth: '4rem', minHeight: '4rem' }}>
              <BsPatchCheckFill className="text-white text-3xl" />
            </div>
            <div className="ml-4">
              <h2 className="text-xl font-semibold">{reason.title}</h2>
              <p className="text-gray-700 mt-2">{reason.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;
