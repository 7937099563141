import image1 from '../assets/Vaibhav.png';
import image2 from '../assets/Ankit1.png';
import image3 from '../assets/Vishal1.png';

const OurTeam = () => {
  return (
    <div className="h-[200vh] md:h-[125vh] xl:h-screen flex flex-col items-center py-10">
      <div className="self-center md:self-start md:ml-10 xs:max-md:pb-5">
        <h1 className="text-primary-orange text-bold text-3xl md:text-4xl xs:max-md:pb-10 mb-10">Meet the Team</h1>
      </div>
      <div className='flex xs:max-md:self-center justify-center h-[80%] xl:h-[90%] mt-10'>
        <div className="flex md:bg-primary-orange md:h-[50px] lg:h-[70px] xl:h-[100px] w-full self-center">
          <div className='flex flex-col md:flex-row justify-evenly items-center'>
            <div className='xs:max-md:my-5 flex flex-col justify-center items-center w-[80%] md:w-[20%] lg:w-[20%] md:translate-y-[20%] lg:translate-y-[15%]  xl:translate-y-[7%] '>
              <img src={image1} alt="Vaibhav" className='xs:max-md:max-h-[220px] md:min-h-[100px] lg:min-h-[200px] xl:min-h-[300px] ' />
              <h1 className='text-bold text-xl md:text-lg lg:text-xl mt-3'>Vaibhav</h1>
              <h3 className='text-bold mb-3 text-lg md:text-sm lg:text-xl'>Founder and Director</h3>
              <p className='text-justify md:text-center lg:text-justify md:text-sm xl:text-base'>
                With over 14 years of experience in manufacturing, operations, and chemicals, Vaibhav plays a pivotal role in overseeing all manufacturing, technical, and operational aspects of the company, contributing to our success.
              </p>
            </div>
            <div className='xs:max-md:my-5 flex flex-col justify-center items-center w-[80%] md:w-[20%] lg:w-[25%] md:translate-y-[25%] lg:translate-y-[20%] xl:translate-y-[18%]'>
              <img src={image2} alt="Ankit" className='xs:max-md:max-h-[300px] md:min-h-[100px] lg:min-h-[200px] xl:min-h-[300px]' />
              <h1 className='text-bold text-xl md:text-lg lg:text-xl mt-3'>Ankit</h1>
              <h3 className='text-bold mb-3 text-lg md:text-sm lg:text-xl'>Founder and Director</h3>
              <p className='text-justify md:text-center lg:text-justify md:text-sm xl:text-base'>
                With 12 years of experience in the retail field, Ankit specializes in finance, retail, and distribution. He excels in managing all procurement, financial, and distribution activities at Astonish, contributing invaluable expertise to our team.
              </p>
            </div>
            <div className='xs:max-md:my-5 flex flex-col justify-center items-center w-[80%] md:w-[20%] lg:w-[20%] md:translate-y-[30%] lg:translate-y-[27%] xl:translate-y-[28%]'>
              <img src={image3} alt="Vishal" className='xs:max-md:max-h-[300px] xs:max-md:min-h-[200px] md:min-h-[100px] lg:min-h-[200px] xl:min-h-[300px]' />
              <h1 className='text-bold text-xl md:text-lg lg:text-xl mt-3'>Vishal</h1>
              <h3 className='text-bold mb-3 text-lg md:text-sm lg:text-xl'>Founder and Director</h3>
              <p className='text-justify md:text-center lg:text-justify md:text-sm xl:text-base'>
                As our domain expert with over 15 years of experience in the stone industry, Vishal leads all sales and marketing activities at the company, bringing unparalleled expertise and leadership to our team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeam;
