import image from '../assets/granite-color.jpg';

const About = () => {

    const divStyle = {
        backgroundImage: `url(${image})`,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundAttachment: 'fixed',
        height: '65%',
        width: '35%',
        backgroundRepeat: "no-repeat"
    };

    const divStyle2xl = {
        backgroundImage: `url(${image})`, 
        backgroundSize: 'auto 100%',
        backgroundPosition: '70% 10%',
        backgroundAttachment: 'fixed',
        height: '55%',
        width: '35%',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div className="center-align pt-5 md:pt-10 md:h-screen bg-gray-100 flex flex-col md:flex-row justify-center items-center md:px-6">
            <div className="md:w-3/5 2xl:4/5 px-10 lg:px-5 flex flex-col justify-center items-center md:items-start">
                <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-6 text-center md:text-left">
                    Astonish: Your Stone Partner
                </h1>
                <p className=" md:text-sm xl:text-lg lg:text-base leading-relaxed text-center md:text-left">
                    At Astonish, we're dedicated to revolutionizing the Stone (Granite & Marble) processing industry. As a leading manufacturer and distributor, we offer a diverse range of over 20 meticulously curated SKUs, from essential chemicals to top-tier hardware, seamlessly meeting your granite and marble processing needs.                    <br />
                    <br />
                    What truly sets us apart is our unwavering commitment to excellence. Our products are not merely designed; they are masterfully crafted and expertly formulated by industry-leading technical consultants. We take quality to the next level by subjecting every product to rigorous on-site testing, ensuring that they consistently meet and exceed the highest industry standards.
                    <br />
                    <br />
                    Discover the Astonish difference – where innovation, quality, and performance converge to elevate your stone processing experience.
                </p>
                <a href="/about-us">
                <button className="my-8 hover:bg-primary-orange hover:border-primary-orange bg-transparent text-black hover:text-white font-bold py-2 px-4 rounded-md border-black border-2 lg:text-lg">
                    Learn more
                </button>
                </a>
            </div>
            <div className="hidden 2xl:block 2xl:w-1/5 rounded-lg h-full justify-center items-center" style={divStyle2xl}>
            </div>

            <div className="2xl:hidden md:w-2/5 rounded-lg h-full md:flex justify-center items-center hidden" style={divStyle}>
            </div>
        </div>
    );
};

export default About;
