import image from '../assets/bg-astonish-logo.png';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const DealershipForm = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (form.current) {
      const serviceId = 'service_u1woplh';
      const templateId = 'template_7s1ieg9';
      const publicKey = 'eapDO2b9GoB6B0VoA';

      emailjs
        .sendForm(serviceId, templateId, form.current, publicKey)
        .then(
          (result) => {
            console.log('Email sent successfully:', result.text);
            setIsSuccess(true);
            if (form.current) {
              form.current.reset();
            }
          },
          (error) => {
            console.error('Email error:', error.text);
            setIsError(true);
          }
        );
    }
  };

  return (
    <div className="center-align p-10 xs:max-lg:px-0 flex items-center justify-center min-h-screen">
      <div className="w-1/2 xs:max-lg:w-3/4">
        <div className="container mx-auto xs:max-lg:px-0 py-10 px-6">
          <div className="text-center mt-10">
            <h1 className="text-4xl font-bold text-gray-800 mb-6 mt-10 pt-5">Dealership Form</h1>
            <p className="text-lg text-gray-600 mb-8">
              If you're interested in becoming a partner, please fill out the form below, and we'll get back to you shortly.
            </p>
          </div>
          <div className="flex justify-center items-center mt-10">
            <div className="w-full">
              {isSuccess ? (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mb-4">
                  Message sent successfully!
                </div>
              ) : null}
              {isError ? (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
                  Error sending message. Please try again later.
                </div>
              ) : null}
              {isSuccess || isError ? null : (
                <>
                </>
              )}
              <form ref={form} onSubmit={sendEmail}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-600">
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="from_name"
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:ring-primary-orange"
                    placeholder="John Doe"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-600">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company_name"
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:ring-primary-orange"
                    placeholder="Acme Inc."
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-600">
                    Contact Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="contact_number"
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:ring-primary-orange"
                    placeholder="+1 123-456-7890"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                    Your Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="reply_to"
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:ring-primary-orange"
                    placeholder="john@example.com"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-600">
                    Company Address
                  </label>
                  <textarea
                    id="address"
                    name="company_address"
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:ring-primary-orange"
                    placeholder="123 Main St, City, State, ZIP"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-primary-orange hover:bg-primary-orange-dark text-white font-bold py-2 px-4 rounded-md"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="xs:max-lg:hidden w-1/2 flex items-center justify-center">
        <img src={image} alt="granite" className="w-1/2" />
      </div>
    </div>
  );
};

export default DealershipForm;