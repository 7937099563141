import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import OurProducts from './pages/OurProducts';
import ContactUs from './pages/ContactUs';
import DealershipForm from './pages/DealershipForm';
function App() {
  return (
    <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-products" element={<OurProducts />} />
          <Route path="/dealership-form" element={<DealershipForm />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
