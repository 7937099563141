import image from "../assets/black-granite-bg.jpg";
import OurTeam from "../components/OurTeam";
import WhyUs from "../components/WhyUs";

const AboutUs = () => {
  const divStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
  };

  return (
    <div className="center-align">
      <div className="h-[100vh] md:h-screen">
        <div className="relative h-[100vh] md:h-full" style={divStyle}></div>
        <div className="center-align absolute top-0 w-full h-[100vh] md:h-full bg-black opacity-30"></div>
        <div className="center-align absolute mt-10 inset-0 flex flex-col items-center justify-center z-10">
          <div className="bg-transparent max-w-[90%] sm:max-w-[80%] md:max-w-[50%] p-6 md:mb-10 mt-10 md:mt-10 head-fade-in">
            <h1 className="text-white font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mb-2 sm:mb-5 mt-5">
              Who Are We?
            </h1>
            <p className="text-justify text-white text-xs sm:text-sm md:text-base lg:text-lg">
            At Astonish, we stand as your trusted partner for all Stone (Granite & Marble) processing essentials. Our commitment lies in meeting every requirement, offering a seamless experience for our valued clients.            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center md:justify-around md:mt-10">
            <div className="bg-transparent max-w-[90%] sm:max-w-[80%] md:max-w-[35%] lg:max-w-[30%] xl:max-w-[30%] p-6 vis-fade-in">
              <h1 className="text-white font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mb-2 sm:mb-5">
                Our Vision
              </h1>
              <p className="text-justify text-white text-xs sm:text-sm md:text-base lg:text-lg">
              We aim to elevate Stone (Granite & Marble) processing, setting new standards for perfection, brilliance, and precision in every construction project we touch.              </p>
            </div>
            <div className="bg-transparent max-w-[90%] sm:max-w-[80%] md:max-w-[35%] lg:max-w-[30%] xl:max-w-[30%] p-6 mis-fade-in">
              <h1 className="text-white font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mb-2 sm:mb-5">
                Our Mission
              </h1>
              <p className="text-justify text-white text-xs sm:text-sm md:text-base lg:text-lg">
                Our commitment lies in continuous innovation, bringing forth advanced stone processing products with maximum efficiency, ensuring we achieve our vision.
              </p>
            </div>
          </div>
        </div>
      </div>
      <OurTeam />
      <WhyUs />
    </div>
  );
};

export default AboutUs;
