import { BsLinkedin } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className="center-align bg-gray-900 text-white pt-10">
      <div className="container mx-auto px-6">
        <div className="flex flex-col lg:flex-row justify-between gap-8 pb-6">
          <div>
            <h1 className="text-xl font-semibold mb-2">Plant Address</h1>
            <p>Astonish Chemicals Pvt. Ltd.<br />Lakadwas Industrial Area Main Road,<br />near PHC Lakadwas,<br />Udaipur - 313002</p>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">Registered Address</h1>
            <p>2-J-16 Prabhat Nagar, Sector 5,<br />Udaipur - 313002</p>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">Phone Number</h1>
            <p>+91 9414239402<br />+91 9414156113<br />+91 9982900735</p>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">Email</h1>
            <a href="mailto:india.astonish@gmail.com" className='hover:text-[#FFA500]'>astonish.hr@gmail.com</a>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">Follow us on</h1>
            <a href='https://www.linkedin.com/in/vaibhav-mittal-78b41726/' target="_blank" rel="noopener noreferrer">            
            <BsLinkedin className='text-xl hover:text-[#FFA500]' />
            </a>
          </div>
        </div>
        <div className="text-center mt-8 pb-2 text-sm">
          {/* Website created by Anukrati Mehta */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
